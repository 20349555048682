import { observer } from 'mobx-react-lite'
import { getSnapshot } from 'mobx-state-tree'
import { useEffect, useContext, useState, useLayoutEffect, useRef, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import CheckboxTree from 'react-checkbox-tree'

import Icon, { ICONS_TYPES, ROTATE_TYPES } from '@/components/Icon'
import Button, { BUTTON_TYPES, BUTTON_COLORS } from '@/components/Button'
import Search from '@/components/Search'

import { StoreContext } from '@/store'

import pageStyles from '@/styles/page.module.css'
import styles from './index.module.css'

const dataTransform = (data = [], parentID = '', type = '') => {
  return data.map(item => {
    const nextParentID = parentID ? `${parentID}-${item.id}` : item.id
    const valuePrefix = type ? `${type}-` : ''
    const res = {
      value: `${valuePrefix}${item.id}`,
      label: item.name,
    }

    if (item.resources && item.resources.length > 0) {
      res.children = dataTransform(item.resources, nextParentID, 'resources')
    }

    if (item.fields?.length) {
      res.children = dataTransform(item.fields, nextParentID, 'field')
    }

    return res
  })
}

const findAllParentPathsByLabel = (arr, targetLabel) => {
  const res = new Set()

  const search = (item, path) => {
    if (item.label.toLowerCase().trim().includes(targetLabel.toLowerCase().trim())) {
      path.forEach(value => res.add(value))
    }

    if (item.children) {
      for (let child of item.children) {
        search(child, path.concat(item.value))
      }
    }
  }

  for (let item of arr) {
    search(item, [])
  }

  return Array.from(res)
}

const Module = () => {
  const store = useContext(StoreContext)
  const { httpStore, formsStore, authStore } = store
  const { moduleId } = useParams()
  const [expanded, setExpanded] = useState([])
  const [changed, setChanged] = useState(false)

  const [search, setSearch] = useState('')
  const [notSearched, setNotSearched] = useState(false)
  const searchRef = useRef(null)

  const formName = 'companyAllowFieldsSet'
  const form = formsStore.getForm(formName)

  const modulesArgs = [
    'viewModule',
    [
      'id',
      'name',
      {
        standards: [
          'id',
          'name',
          { resources: [
            'id',
            'name',
            {
              'fields': [
                'id',
                'name',
                { unit: ['id', 'name', 'multiplier', 'parentId'] },
              ],
            },
          ] },
        ],
      },
    ],
    { 'moduleId': moduleId },
    {
      cacheTime : 1000 * 60 * 60,
    },
  ]
  const { get: getModules, loaded } = httpStore.fetchRequest(...modulesArgs)
  const module = getModules('data', {})
  const moduleFields = (module?.standards || []).reduce((m1, s) => {
    return [
      ...m1,
      ...(s?.resources || []).reduce((m2, r) => {
        return [
          ...m2,
          ...(r.fields || []).map(f => f.id),
        ]
      }, []),
    ]
  }, [])

  const standards = []
  if (loaded && module.id && standards.length < 1) {
    standards.push({
      value: `module-${module.id}`,
      label: module.name,
      children: dataTransform(
        module.standards,
        module.id,
        'standards'
      ),
    })
  }

  useEffect(() => {
    if (loaded && moduleId && (!expanded?.length || !expanded.includes(`module-${moduleId}`))) {
      setExpanded([`module-${moduleId}`])
      searchRef.current.reset()
    }
  }, [loaded, moduleId, expanded])

  const company = authStore?.user?.company

  useEffect(() => {
    formsStore.createForm(formName, {
      form: {
        data: getSnapshot(company),
      },
    }, true)
  }, [company, formsStore])

  let allowedFields
  let checked
  if (form) {
    allowedFields = form.form.get('data.allowedFields', [])
    checked = allowedFields.map(item => `field-${item.id}`)
  }

  const setChecked = useCallback((newAllowedFields) => {
    const otherAllowedFields = allowedFields.filter(af => !moduleFields.includes(af.id))
    const normalizedAllowedFields = [
      ...otherAllowedFields,
      ...newAllowedFields.reduce((m, naf) => {
        if (!/^field-/.test(naf)) return m
        const fieldId = naf.replace('field-', '')
        const field = allowedFields.find(af => af.id === fieldId) || { id: fieldId }
        return [
          ...m,
          field,
        ]
      }, []),
    ]
    form.form.setValue('data.allowedFields', normalizedAllowedFields)
    setChanged(true)
  }, [moduleFields, allowedFields, form])

  useLayoutEffect(() => {
    const allSearched = document.querySelectorAll('span.' + styles.search)
    if (allSearched && allSearched.length > 0) {
      allSearched.forEach(element => {
        element.classList.remove(styles.search)
      })
    }

    if (search) {
      const titles = document.querySelectorAll('span.rct-title')
      const matchedElements = Array.from(titles).filter(title =>
        title.textContent.toLowerCase().trim().includes(search.toLowerCase().trim())
      )

      if (matchedElements.length > 0) {
        matchedElements.forEach(title => {
          const parent = title.closest('span.rct-text')
          parent && parent.classList.add(styles.search)
        })

        const firstElementPosition = matchedElements[0].getBoundingClientRect().top + window.scrollY - 30
        window.scrollTo({ top: firstElementPosition, behavior: 'smooth' })
      }
    }
  }, [search])

  useEffect(() => {
    if (search && expanded.length < 1) {
      setNotSearched(true)
    } else {
      setNotSearched(false)
    }
  }, [search, expanded])

  const onSearch = (search) => {
    if (search) {
      setExpanded(findAllParentPathsByLabel(standards, search))
    } else {
      setExpanded([`module-${moduleId}`])
    }
    setSearch(search)
  }

  if (!loaded || !allowedFields) return null

  return (
    <div className={pageStyles.page}>
      <div className={pageStyles.header}>
        <h1 className={pageStyles.title}>
          Модуль - {module?.name}
        </h1>
        <div className={pageStyles.headerControls}>
          <Button
            type={BUTTON_TYPES.fill}
            color={BUTTON_COLORS.blue}
            disabled={form?.form?.hasErrors || !changed}
            onClick={() => form?.form.submit(
              'upsertCompany',
              [
                'id',
                'name',
                {
                  allowedFields: [
                    'id',
                    'name',
                    'type',
                    'formula',
                    { unit: ['id', 'name', 'multiplier', 'parentId'] },
                    'orderNumber',
                    'resourceId',
                    'standardId',
                    'companyId',
                  ],
                },
              ],
              v => ({
                company: {
                  ...v,
                  allowedFields: (v.allowedFields || []).map(af => ({ id: af.id, orderNumber: af.orderNumber })),
                },
              })
            ).then(({ data }) => {
              authStore.setCompany(data)
              setChanged(false)
              httpStore.resetCallTime()
            })}
          >
            Сохранить
          </Button>
        </div>
      </div>
      <div className={pageStyles.block}>
        <div className={styles.header}>
          <h2 className={styles.title}>
            Standards
          </h2>
          <Search
            className={styles.search}
            ref={searchRef}
            onSearch={onSearch}
          />
        </div>
        <div className={styles.header}>
          <div/>
          <Button
            type={BUTTON_TYPES.dashed}
            color={BUTTON_COLORS.blue}
            onClick={() => {
              const formName = 'createField'
              formsStore.createForm(formName, {
                modalComponent: 'FieldEditModal',
                form: {
                  data: {
                    moduleId,
                  },
                },
                props: {
                  onSuccess: () => {
                    httpStore.resetCallTime()
                    httpStore.fetchRequest(...modulesArgs)
                  },
                },
              })
            }}
          >
            <span>+ Создать параметр</span>
          </Button>
        </div>
        {notSearched && <div className={styles['not-found']}>Не найдено</div>}
        <CheckboxTree
          id={styles['checkbox-tree']}
          nodes={standards}
          checked={checked}
          expanded={expanded}
          onCheck={(checked) => setChecked(checked)}
          onExpand={(expanded) => setExpanded(expanded)}
          icons={{
            expandClose: <Icon
              className={styles.icon}
              type={ICONS_TYPES.arrowDown}
              rotate={ROTATE_TYPES.up}
            />,
            expandOpen: <Icon
              className={styles.icon}
              type={ICONS_TYPES.arrowDown}
              rotate={ROTATE_TYPES.down}
            />,
          }}
        />
      </div>
    </div>
  )
}

export default observer(Module)
