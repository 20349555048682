import { useCallback, useContext } from 'react'

import { StoreContext } from '@/store'
import { getApiUrl } from '@/store/helpers/fetch'

import Button, { BUTTON_TYPES as FILE_INPUT_TYPES, BUTTON_COLORS as FILE_INPUT_COLORS } from '../Button'

import styles from './index.module.css'


const url = getApiUrl()

const uploadFile = ({
  method = 'POST',
  data = [],
  headers,
}) => {
  let formData = new FormData()
  data.forEach(({ key, value }) => {
    formData.append(key, value)
  })

  return fetch(url, {
    method,
    headers,
    body: formData,
  })
    .then(res => res.json())
}

const FileInput = ({ onChange, onClick, link, multiple, operationName, ...props }) => {
  const store = useContext(StoreContext)
  const { authStore: { token } } = store

  const handleChange = useCallback(({ target }) => {
    const { files } = target
    if (!operationName) {
      onChange(files)
      return
    }
    const data = []
    if (multiple) {
      // TODO
    } else {
      data.push({
        key: 'operations',
        // eslint-disable-next-line max-len
        value: `{"query":"mutation UploadFile($file: Upload!) {${operationName}(file: $file)}","operationName":"UploadFile","variables":{"file":null}}`,
      })
      data.push({
        key: 'map',
        value: '{"0":["variables.file"]}',
      })
      data.push({
        key: '0',
        value: files[0],
      })
    }
    const headers = {
      Authorization: token,
      'x-apollo-operation-name': operationName,
    }
    return uploadFile({ data, headers })
      .then(onChange)
  }, [onChange, multiple, token, operationName])
  return (
    <label>
      <input type="file" multiple={multiple} className={styles.fileInput} onChange={handleChange} />
      <Button {...props} />
    </label>
  )
}

export default FileInput

export { FILE_INPUT_TYPES, FILE_INPUT_COLORS }
