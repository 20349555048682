const enumRegex = /^@enum\((.*)\)$/

const wrapBodyValue = (value) => {
  if (value === null) {
    return 'null'
  }

  if (typeof value === 'object') {
    if (Array.isArray(value)) {
      return `[${value.map(v => wrapBodyValue(v))}]`
    }
    return `{ ${toGraphBody(value)} }`
  }

  if (typeof value === 'number') {
    return value
  }

  if (typeof value === 'boolean') {
    return value ? 'true' : 'false'
  }

  if (typeof value === 'string') {
    const match = value.match(enumRegex)
    if (match && match.length) {
      const enumValue = match[1]
      return enumValue ? enumValue : 'null'
    }
    return `"${value}"`
  }

  throw Error('Graph body unknown type')
}

export const toGraphBody = (data = {}) => {
  return Object.entries(data).reduce((memo, [key, value]) => {
    if (value === undefined) return memo
    const current = `${key}: ${wrapBodyValue(value)}`
    return memo ? `${memo}, ${current}` : current
  }, '')
}

const wrapQueryValue = (data = {}) => {
  return Object.entries(data).reduce((memo, [key, value]) => {
    const current = `${key} { ${toGraphQuery(value)} }`
    return memo ? `${memo} ${current}` : current
  }, '')
}

export const toGraphQuery = (data = []) => {
  return data.reduce((memo, value) => {
    const current = typeof value === 'object' ? `${wrapQueryValue(value)}` : value
    return memo ? `${memo} ${current}` : current
  })
}
