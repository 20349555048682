export const EMPTY_VALUE_ERR_MSG = 'Значение обязательно'

export const USER_TOKEN_NOT_FOUND = 'Пользователь с таким токеном не найден.'

export const INCORRECT_PHONE_FORMAT = 'Некорректный номер телефона'

export const INCORRECT_MAIL_FORMAT = 'Некорректный E-Mail'

export const INCORRECT_MAIL_OR_PASSWORD = 'Неверный E-Mail пользователя или пароль'

export const ROLE_DICT = {
  SYSTEM: 'Системный',
  ADMIN: 'Администратор',
  SENIOR_MANAGER: 'Старший менеджер',
  MANAGER: 'Менеджер',
}

export const STATUS_DICT = {
  INVITED: 'Приглашен',
  ACTIVE: 'Активный',
  DEACTIVATED: 'Деактивирован',
}

export const FIELD_TYPE_DICT = {
  STRING: 'Строка',
  NUMBER: 'Число',
  null: 'Не указан',
}

export const HALF_YEAR_DICT = {
  1: 'Первое',
  2: 'Второе',
}
