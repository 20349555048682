import { types } from 'mobx-state-tree'

import { Form } from '../FormStore'


const Division = types.model('Division', {
  id: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
})

const Unit = types.model('Division', {
  id: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  multiplier: types.maybeNull(types.number),
  parentId: types.maybeNull(types.string),
})

const Field = types.model('MainField', {
  id: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  unit: types.maybeNull(Unit),
  type: types.maybeNull(types.string),
  formula: types.maybeNull(types.string),
  orderNumber: types.maybeNull(types.number),
  resourceId: types.maybeNull(types.string),
  standardId: types.maybeNull(types.string),
  companyId: types.maybeNull(types.string),
})

const Company = types.model('Company', {
  id: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  allowedFields: types.maybeNull(types.array(Field)),
})

const User = types.model('User', {
  id: types.maybeNull(types.string),
  email: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  status: types.maybeNull(types.string),
  role: types.maybeNull(types.string),
  company: types.maybeNull(Company),
  division: types.maybeNull(Division),
})

const authDataQuery = [
  'id',
  'token',
  'email',
  'name',
  'status',
  'role',
  { division: ['id', 'name'] },
  {
    company: [
      'id',
      'name',
      {
        allowedFields: [
          'id',
          'name',
          'type',
          'formula',
          { unit: ['id', 'name', 'multiplier', 'parentId'] },
          'orderNumber',
          'resourceId',
          'standardId',
          'companyId',
        ],
      },
    ],
  },
]

const AuthStore = types
  .model('AuthStore', {
    user: types.optional(User, {}),
    token: types.maybeNull(types.string),
    form: types.optional(Form, {}),
  })
  .actions(self => ({
    setToken(token) {
      self.token = token
    },
    setUser(user) {
      self.user = user
    },
    setCompany(company) {
      self.user.company = company
    },
    login(navigate) {
      self.form.submit('login', authDataQuery)
        .then(res => {
          const { token, ...user } = res?.data || {}
          if (!token) return Promise.reject(res)

          self.setToken('Bearer ' + token)
          self.setUser(user)
          if (typeof navigate === 'function') navigate('/')
          setTimeout(() => {
            self.form.setValue('data', {})
            self.form.setValue('errors', {})
          }, 300)
        })
        .catch(() => {})
    },
    register(navigate) {
      self.form.submit('register', authDataQuery)
        .then(res => {
          const { token } = res?.data || {}
          // const { token, ...user } = res?.data || {}
          if (!token) return Promise.reject(res)

          //self.setToken('Bearer ' + token)
          //self.setUser(user)
          if (typeof navigate === 'function') navigate('/')
          setTimeout(() => {
            self.form.setValue('data', {})
            self.form.setValue('errors', {})
          }, 300)
        })
        .catch(() => {})
    },
    logout(navigate) {
      self.setToken(null)
      self.setUser({})
      if (typeof navigate === 'function') navigate('/')
    },
  }))

export default AuthStore
