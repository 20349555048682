import { allCountries, iso2Lookup, allCountryCodes } from 'country-telephone-data'
import memoizeOne from 'memoize-one'

const noDots = /[^\u002e]/g
const noDigitsRegexp = /\D/g
const mailRegexp = /[^@]+@[^@]+(\.[^@]+)?\.\w{2,}|^$/

const phoneExtFormat = ` ex ${Array(15).fill('.').join('')}`

const toFormat = (format) => (value = '') => {
  return format.split('').reduce((memo, char) => {
    if (memo.remainingText.length === 0) {
      return memo
    }
    if (char !== '.') {
      return {
        formattedText: memo.formattedText + char,
        remainingText: memo.remainingText,
      }
    }

    return {
      formattedText: memo.formattedText + memo.remainingText[0],
      remainingText: memo.remainingText.slice(1, memo.remainingText.length),
    }
  }, {
    formattedText: '',
    remainingText: value.replace(noDigitsRegexp, ''),
  }).formattedText
}

const fromFormat = (replacementRegex, len, fromEnd) => (value = '') => {
  const formatted = value.toString().replace(replacementRegex, '')
  if (fromEnd) return formatted.slice(-len)
  return formatted.slice(0, len)
}

const maxPhoneCodeLength = Math.max(...Object.keys(allCountryCodes).map(k => k.length))

const minPhoneLength = allCountries.reduce((memo, country) => {
  const { length } = fromFormat(noDots)(country.format || '')
  if (length > 0 && length < memo) return length
  return memo
}, Infinity)

const getPhoneFormat = (value = '') => {
  let countryFormat
  for (let i = value.length; i > 0; i -= 1) {
    const subValue = value.substring(0, i)
    const countries = allCountryCodes[subValue]
    if (countries?.length) {
      const countryIndex = iso2Lookup[countries[0]]
      const country = allCountries[countryIndex]
      if (country.format) {
        countryFormat = country.format
        return countryFormat
      }
    }
  }
  return undefined
}

const memoizedGetPhoneFormat = memoizeOne(getPhoneFormat)

export const checkPhoneFormat = (value = '') => {
  let length = minPhoneLength
  const digitValue = fromFormat(noDigitsRegexp)(value)
  const format = memoizedGetPhoneFormat(digitValue.substring(0, maxPhoneCodeLength))
  if (format) length = fromFormat(noDots)(format).length
  return digitValue.length >= length
}

export const toPhone = (value = '') => {
  const digitValue = fromFormat(noDigitsRegexp)(value)
  const phoneFormat = memoizedGetPhoneFormat(digitValue.substring(0, maxPhoneCodeLength))
  if (!phoneFormat) return digitValue
  return toFormat(`${phoneFormat}${phoneExtFormat}`)(digitValue)
}

export const fromPhone = (value = '') => {
  return fromFormat(noDigitsRegexp)(value)
}

export const toInt = (value = '') => {
  let v = value.replace(/\D/, '')
  v = Number.parseInt(v)
  return Number.isNaN(v) ? '' : v
}

export const fromInt = (value = '') => value.toString()

export const checkMailFormat = (value = '') => {
  return mailRegexp.test(value)
}

export const humanizeNumber = (number, once, twice, many, zero) => {
  if (number === 0 && zero !== undefined) return zero
  const rest100 = number % 100
  const rest10 = number % 10
  if (rest100 > 10 && rest100 < 15) return `${number} ${many}`
  if (rest10 === 1) return `${number} ${once}`
  if (rest10 > 1 && rest10 < 5) return `${number} ${twice}`
  return `${number} ${many}`
}
